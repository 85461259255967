import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import UserStakings from "./UserStakings";
import MenberTable1 from "./MenberTable1";
import { useParams } from "react-router";
import { IFTContext } from "../../IFTContext";
import axios from "axios";
import Copy from "../Comman/Copy";
import UserStakingInc from "./UserStakingInc";
import UserROR from "./UserROR";
import UserLevelIncome from "./UserLevelIncome";
import UserWithdrawal from "./UserWithdrawal";
import UserPWithdrawal from "./UserPWithdrawal";

export default function MemberReport() {
  const { address } = useParams();
  const { formatAddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        u_id: address,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, [address]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Member Report</a>
                </li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                />
                <div className="input-group-append">
                  <button type="button" className="input-group-text">
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="profile-statistics">
                  <div className="row ">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col">
                          <h5 className="m-b-0">
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                              rel="noreferrer"
                            >
                              {address}
                            </a>
                            {address ? <Copy data={address} /> : ""}
                          </h5>
                          <span>User ID</span>
                        </div>
                        <div className="col">
                          <h5 className="m-b-0">
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                              rel="noreferrer"
                            >
                              {data ? data.ref_id : ""}
                            </a>
                            {data.ref_id ? (
                              <Copy data={data.ref_id} />
                            ) : (
                              ""
                            )}
                          </h5>
                          <span>Ref ID</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-3">
                          <h5 className="m-b-0">
                            $ {Number(data.t_staking).toFixed(2)}
                          </h5>
                          <span>Total Staking</span>
                        </div>
                        <div className="col-lg-3">
                          <h5 className="m-b-0">{data.t_direct}</h5>
                          <span>Direct Members</span>
                        </div>
                        
                        <div className="col-lg-3 ">
                          <h5 className="m-b-0">
                            {data.team}
                          </h5>
                          <span>Team Members</span>
                        </div>
                        <div className="col-lg-3 ">
                          <h5 className="m-b-0">
                            $ {Number(data.team_business * 10).toFixed(
                              2
                            )}
                          </h5>
                          <span>Team Business</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">
                            ${" "}
                            {Number(
                              data.staking_rew +
                                data.level_rew +
                                data.ror_rew -
                                data.t_withdrawal
                            ).toFixed(2)}
                          </h5>
                          <span>Balance</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">
                            $ {Number(data.staking_rew).toFixed(2)}
                          </h5>
                          <span>ROI</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">
                            $ {Number(data.level_rew).toFixed(2)}
                          </h5>
                          <span>Referral</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">$ {Number(data.ror_rew).toFixed(2)} </h5>
                          <span>R.O.R</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">
                            $ {Number(data.t_withdrawal).toFixed(2)}
                          </h5>
                          <span>Withdrawal</span>
                        </div>
                        <div className="col-lg-3 pt-3">
                          <h5 className="m-b-0">
                            $ {Number(data.p_with).toFixed(2)}
                          </h5>
                          <span>Pending Withdrawal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UserStakings address={address} />
            <UserStakingInc address={address} />
            <UserROR address={address} />
            <UserLevelIncome address={address} />
            <UserWithdrawal address={address} />
            <UserPWithdrawal address={address} />
            {/* <MenberTable2 /> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
