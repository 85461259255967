import React from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'

export default function Gold() {
    return (
        <>
            <div id="main-wrapper">
                <Header />
                <Sidebar />
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                        <div className="page-titles">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                <li className="breadcrumb-item active"><a href="#">Gold</a></li>
                            </ol>
                        </div>
                        <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
                            <div className="input-group search-area d-inline-flex me-2">
                                <input type="text" className="form-control" placeholder="Search here" />
                                <div className="input-group-append">
                                    <button type="button" className="input-group-text"><i className="flaticon-381-search-2" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="checkAll" required />
                                                                    <label className="form-check-label" htmlFor="checkAll" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Date Of Appointment</th>
                                                        <th>From</th>
                                                        <th>To</th>
                                                        <th>Mobile</th>
                                                        <th>Consulting Doctor</th>
                                                        <th>Injury/Condition</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox1" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox1" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/1.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Matthew</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Fever</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox2" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox2" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/2.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">JOHN D RANDOLPH</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Fever</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox3" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox3" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/3.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">JOHN D RANDOLPH</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Acne</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox4" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox4" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/4.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Asher Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Anaphylaxis</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox8" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox8" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/6.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Carter Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Angioedema</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox7" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox7" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/4.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">David Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Anxiety</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox6" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox6" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/6.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Anthony David</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Arthritis</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox1" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox1" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/1.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Matthew</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Fever</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox2" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox2" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/2.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">JOHN D RANDOLPH</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Fever</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox3" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox3" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/3.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">JOHN D RANDOLPH</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Acne</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox4" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox4" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/4.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Asher Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Anaphylaxis</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox8" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox8" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/6.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Carter Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Angioedema</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox7" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox7" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/4.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">David Anthony</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Anxiety</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="checkbox text-end align-self-center">
                                                                <div className="form-check custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox6" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox6" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="patient-info ps-0">
                                                            <span>
                                                                <img src="images/avatar/6.jpg" alt="" />
                                                            </span>
                                                            <span className="text-nowrap ms-2">Anthony David</span>
                                                        </td>
                                                        <td className="text-primary">gabriel@gmail.com</td>
                                                        <td>8 Aug 2021</td>
                                                        <td>12:00</td>
                                                        <td>13:00</td>
                                                        <td className="text-primary">9876512345</td>
                                                        <td>Dr. HANI B BARADI</td>
                                                        <td>Arthritis</td>
                                                        <td>
                                                            <span className="me-3">
                                                                <a href="javascript:void(0);" className="edit-appointment"><i className="fa fa-pencil fs-18 " aria-hidden="true" /></a>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Book Appointment</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">Title:</label>
                                                    <select className="form-control">
                                                        <option>Miss</option>
                                                        <option>Mr.</option>
                                                        <option>Mrs.</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Name:</label>
                                                    <input type="text" className="form-control" id="name1" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Last Name:</label>
                                                    <input type="text" className="form-control" id="name2" placeholder="Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">Date Of Appointment:</label>
                                                    <input size={16} type="date" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="form-label mt-3">Form<span className="text-danger">*</span></label>
                                                <div className="input-group clockpicker">
                                                    <input type="text" className="form-control" defaultValue="09:30" /><span className="input-group-text"><i className="fas fa-clock" /></span>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="form-label mt-3">To<span className="text-danger">*</span></label>
                                                <div className="input-group clockpicker">
                                                    <input type="text" className="form-control" defaultValue="10:30" /><span className="input-group-text"><i className="fas fa-clock" /></span>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">Address :</label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Mobile No:</label>
                                                    <input type="number" className="form-control" id="moblie1" placeholder="Mobile" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Email Id:</label>
                                                    <input type="email" className="form-control" id="email1" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Consulting Doctor:</label>
                                                    <select className="form-control">
                                                        <option>Dr.HANI B BARADI</option>
                                                        <option>Dr.NAJJIA N MAHMOUD</option>
                                                        <option>Dr. SANKAR NAIDU ADUSUMILLI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Injury/Condition:</label>
                                                    <input type="text" className="form-control" id="fever" placeholder="fever" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">Note:</label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea2" rows={3} defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Send message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>s
        </>
    )
}
