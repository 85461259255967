import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Members from "./Components/Members/Members";
import Coin from "./Components/Unstake/Coin";
import Gold from "./Components/Unstake/Gold";
import MemberReport from "./Components/Members/MemberReport";
import StakingsTable from "./Components/Report/StakingsTable";
import Login from "./Components/Login";
import StakingInc from "./Components/Report/StakingInc";
// import RORInc from "./Components/Report/RORInc";
import LevelInc from "./Components/Report/LevelInc";
import Withdrawal from "./Components/Report/Withdrawal";
import Cron from "./Components/Report/Cron";
import WithdrawalPending from "./Components/Report/WithdrawalPending";
import StakingsPoolTable from "./Components/Report/StakingsPoolTable";
import ProtecteRoutes from "./ProtecteRoutes";
import QrCodeAuth from "./Components/QrCodeAuth";
import KYCrecords from "./Components/Report/KYCrecords";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Outlet />}> */}
          <Route path="/" element={<Login />} />
          <Route path="/authentication" element={<QrCodeAuth />} />
          <Route element={<ProtecteRoutes />}>
            <Route path="/dashboard" element={<Index />} />
            <Route path="/stakings" element={<StakingsTable />} />
            <Route path="/staking-pool" element={<StakingsPoolTable />} />
            <Route path="/staking-income" element={<StakingInc />} />
            {/* <Route path="/ror-income" element={<RORInc />} /> */}
            <Route path="/level-income" element={<LevelInc />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/pen-withdrawal" element={<WithdrawalPending />} />
            <Route path="/kyc" element={<KYCrecords />} />
            <Route path="/members" element={<Members />} />
            <Route path="/coin" element={<Coin />} />
            <Route path="/gold" element={<Gold />} />
            <Route path="/cron" element={<Cron />} />
            <Route path="/userreport/:address" element={<MemberReport />} />
            </Route>
            {/* </Route> */}
          </Routes>
        </BrowserRouter>
        );
}

        export default App;
