import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <div className="dataTables_length" id="DataTables_Table_0_length">
        <label>
          <select
            name="DataTables_Table_0_length"
            aria-controls="DataTables_Table_0"
            className="custom-select custom-select-sm form-control form-control-sm"
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            <option value={10} select>
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </label>
      </div>
      <div
        className="dataTables_paginate paging_numbers"
        id="DataTables_Table_0_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1 ? true : false}
            >
              {"<<"}
            </button>
          </li>
          {currentPage - 2 > 0 ? (
            <li className="paginate_button page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 2)}
              >
                {currentPage - 2}
              </button>
            </li>
          ) : (
            ""
          )}
          {currentPage - 1 > 0 ? (
            <li className="paginate_button page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                {currentPage - 1}
              </button>
            </li>
          ) : (
            ""
          )}
          <li className="paginate_button page-item active">
            <button className="page-link" disabled={true}>
              {currentPage}
            </button>
          </li>
          {currentPage + 1 <= pages ? (
            <li className="paginate_button page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                {currentPage + 1}
              </button>
            </li>
          ) : (
            ""
          )}
          {currentPage + 2 <= pages ? (
            <li className="paginate_button page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 2)}
              >
                {currentPage + 2}
              </button>
            </li>
          ) : (
            ""
          )}
          <li
            className={`paginate_button page-item ${
              currentPage === pages ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(pages)}
              disabled={currentPage === pages ? true : false}
            >
              {">>"}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
