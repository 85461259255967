import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { IFTContext } from "../../IFTContext";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
import { QRCodeCanvas } from "qrcode.react"; // Import QR code library

export default function WithdrawalPending() {
  const { formatAddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState(null); // For QR code
  const [selectedTotalReceive, setSelectedTotalReceive] = useState(null); // Total Receive Amount
  const [scannedData, setScannedData] = useState(null); // Scanned QR code data
  const [showScanner, setShowScanner] = useState(false); // Toggle scanner
  const [hash, setHash] = useState(""); // State for storing the entered hash
  const [w_id, setW_id] = useState(""); // State for storing the entered hash


  const getData = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getp",
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        if (res.data.error) {
          setdata([]);
          setPages(0);
          setLoading(false);
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [pages, pageSize, currentPage]);

  const handleShowQR = (address, totalReceive,id) => {
    setSelectedAddress(address);
    setSelectedTotalReceive(totalReceive);
    setW_id(id);
    setShowScanner(false); // Ensure scanner is hidden
  };

  const handleScan = (data) => {
    if (data) {
      setScannedData(data);
      try {
        const url = new URL(data); // Parse scanned data as URL
        const address = url.pathname.substring(1); // Extract address
        const amount = url.searchParams.get("amount"); // Extract amount
        setSelectedAddress(address);
        setSelectedTotalReceive(amount);
        setShowScanner(false); // Close scanner after successful scan
      } catch (error) {
        console.error("Invalid QR code format:", error);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const GiveWithdrawal = async () => {
    if (!hash) {
      alert("Please enter a valid hash.");
      return;
    }

    if (!selectedAddress) {
      alert("No address selected for withdrawal.");
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "update",
        key: process.env.REACT_APP_KEY,
        hash: hash, 
        w_id: w_id, 
      });

      if (response.data.data) {
        alert("Withdrawal successful!");
        setHash(""); 
        setSelectedAddress(null);
        setSelectedTotalReceive(null); 
        getData(); 
      } else {
        alert(response.data.error || "Withdrawal failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during withdrawal:", error);
      alert("An error occurred during the withdrawal process. Please try again.");
    }
  };


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Withdrawal
                </li>
              </ol>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">
                        Pending Withdrawal
                      </div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Amount</th>
                            <th>Fee</th>
                            <th>Total Receive</th>
                            <th>Datetime</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={8}>Data loading...</td>
                            </tr>
                          ) : null}
                          {data.map((ele, i) => {
                            return (
                              <tr key={ele.id}>
                                <td>{ele.id}</td>
                                <td>
                                  <a
                                    target="_blank"
                                    className="text-primary"
                                    href={`${process.env.REACT_APP_EXPLORER}#/address/${ele.address}`}
                                    rel="noreferrer"
                                  >
                                    {formatAddress(ele.address)}
                                  </a>
                                  {ele.address ? <Copy data={ele.address} /> : null}
                                  <button
                                    className="btn btn-primary mb-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tronAddressupdate"
                                    onClick={() =>
                                      handleShowQR(ele.address, ele.t_rcv,ele._id)
                                    }
                                  >
                                    ✔
                                  </button>
                                </td>
                                <td>$ {ele.amount}</td>
                                <td>$ {ele.fee}</td>
                                <td>$ {ele.t_rcv}</td>
                                <td>{ele.datetime}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="tronAddressupdate"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">QR Code</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body text-center">
                {showScanner ? (
                  <div>
                    <QRCodeCanvas
                      onResult={(result, error) => {
                        if (!!result) {
                          handleScan(result?.text);
                        }
                        if (!!error) {
                          handleError(error);
                        }
                      }}
                      style={{ width: "100%" }}
                    />
                    <button
                      className="btn btn-danger mt-3"
                      onClick={() => setShowScanner(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ) : selectedAddress ? (
                  <>
                    <QRCodeCanvas
                      value={`tron:${selectedAddress}?amount=${selectedTotalReceive}`}
                      size={200}
                    />
                    <p className="mt-3">
                      <strong>Address: {selectedAddress}</strong>
                    </p>
                    <p className="mt-3">
                      <strong>Total Receive: $ {selectedTotalReceive}</strong>
                    </p>
                    <div className="mt-3">
                      <label htmlFor="hashInput">
                        <strong>Enter Hash:</strong>
                      </label>
                      <input
                        type="text"
                        id="hashInput"
                        className="form-control mt-2"
                        placeholder="Enter transaction hash"
                        value={hash}
                        onChange={(e) => setHash(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <p>No address selected</p>
                )}
                <button
                  className="btn btn-primary mt-3"
                  onClick={GiveWithdrawal} // Call the function
                >
                  Give Withdrawal
                </button>

              </div>
            </div>
          </div>
        </div>


        <Footer />
      </div>
    </>
  );
}
