import React, { useContext, useEffect, useState } from "react";
import { IFTContext } from "../../IFTContext";
import Copy from "../Comman/Copy";
import Pagination from "../Comman/Pagination";
import axios from "axios";

export default function UserStakings({ address }) {
  const { formatAddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        u_id: address,

        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          setdata([]);
          setPages(0);
          setLoading(false);
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, pageSize, address, currentPage]);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body ">
              <div className="d-flex justify-content-between">
                <div className="card-title text-light">All Stakings</div>
                <span
                  className="btn btn-primary btn-sm "
                  onClick={() => getData()}
                >
                  <i
                    className="flaticon-381-repeat-1 "
                    aria-hidden="true"
                  />
                </span>
              </div>
              <hr />
              <div className="table-responsive">
                <table
                  id="example5"
                  className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Hash</th>
                      <th>User ID</th>
                      <th>Data</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8}>Data loading...</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {data.map((ele, i) => {
                      return (
                        <tr>
                          <td>{ele.id}</td>

                          <td>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}}#/transaction/${ele.hash}`}
                              rel="noreferrer"
                            >
                              {formatAddress(ele.hash)}
                            </a>
                            {ele.hash ? <Copy data={ele.hash} /> : ""}
                          </td>
                          <td>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}#/address/${ele.address}`}
                              rel="noreferrer"
                            >
                              {formatAddress(ele.address)}
                            </a>
                            {ele.address ? (
                              <Copy data={ele.address} />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>Amount: ${ele.amount}</td>
                          <td>Start date: {ele.createdAt}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
