import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import IFTContext from './IFTContext';
import { AdminContext } from './AdminContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <IFTContext />
);
reportWebVitals();
