import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { IFTContext } from "../../IFTContext";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
export default function LevelInc() {
  const { formatAddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "level",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          setdata([]);
          setPages(0);
          setLoading(false);
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, pageSize, currentPage]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Level Income
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">
                        Level Income
                      </div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                    <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>To</th>
                            <th>From</th>
                            <th>Level</th>
                            <th>Reward</th>
                            <th>Reward %</th>
                            <th>Staking</th>
                            <th>Flushed</th>
                            <th>Datetime</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={8}>Data loading...</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {data.map((ele, i) => {
                            return (
                              <tr>
                                <td>{ele.id}</td>
                                <td>
                                  <a
                                    target="_blank"
                                    className="text-primary"
                                    href={`${process.env.REACT_APP_EXPLORER}#/address/${ele.to}`}
                                    rel="noreferrer"
                                  >
                                    {formatAddress(ele.to)}
                                  </a>
                                  {ele.to ? <Copy data={ele.to} /> : ""}
                                </td>
                                <td>
                                  <a
                                    target="_blank"
                                    className="text-primary"
                                    href={`${process.env.REACT_APP_EXPLORER}#/address/${ele.from}`}

                                    rel="noreferrer"
                                  >
                                    {formatAddress(ele.from)}
                                  </a>
                                  {ele.from ? <Copy data={ele.from} /> : ""}
                                </td>
                                <td>{ele.level} </td>
                                <td>$ {ele.income} </td>
                                <td>{ele.income_per} %</td>
                                <td>$ {ele.staking} </td>
                                <td>{ele.flushed?"Flushed":""}</td>
                                <td>{ele.datetime}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <Footer />
      </div>
    </>
  );
}
