import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { IFTContext } from "../IFTContext";

function QrCodeAuth() {
  const { setToken } = useContext(IFTContext);
  const [authNumber, setAuthNumber] = useState("");
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const insertCode = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber: authNumber,
        username: state.username,
        _id: state._id,
      });
      const { resCode, error, token } = response.data;

      if (resCode === 200) {
      if (token) {
          setMsg("Verified successfully");
          setShowMsg(true);
          localStorage.setItem("mark#321", token);
          localStorage.setItem(
            "mark#0143",
            JSON.stringify({ username: state.username, id: state._id })
          );
          setToken(token);
          navigate("/dashboard");
        } else {
          setErrorMessage("Unable to generate token.");
          setError(true);
        }
      } else {
        setErrorMessage(error || "Verification failed. Please try again.");
        setError(true);
        // navigate("/authentication");
      }
    } catch (err) {
      console.error("Error inserting data:", err);
      setErrorMessage("Network error. Please try again later.");
      setError(true);
    }
  };

  return (
    <div className="authincation vh-100">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    {/* <div className="text-center mb-3">
                      {data?.qrdata && <img src={data?.qrdata} alt="QR Code" />}
                    </div> */}
                    <div className="form-group">
                      <label className="form-label">
                        Authentication Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAuthNumber(e.target.value)}
                      />
                    </div>

                    {/* {error && (
                      <div className="alert alert-danger solid alert-square">
                        {error}
                      </div>
                    )} */}
                    {error && errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {showMsg && msg && (
                      <div className="alert alert-success" role="alert">
                        {msg}
                      </div>
                    )}
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={insertCode} // Make sure it's a button type
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodeAuth;
