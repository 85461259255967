import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Comman/Sidebar";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import { IFTContext } from "../../IFTContext";
import axios from "axios";

export default function Index() {
  const { dashd, dashdtoday, settings, getSetting } = useContext(IFTContext);
  const [roiper, setroiper] = useState(0);
  const [tronAddress, settronAddress] = useState(null);
  const [statusup, setstatusup] = useState("");
  const upPer = async () => {
    setstatusup("")
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "perupdate",
        per: roiper,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        getSetting();
        if (res.data.error) {
          setstatusup(res.data.error)
          return "";
        }
        setstatusup("Updated succesfully")

      });
  };
  const upAddress = async () => {
    setstatusup("")
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "update",
        address: tronAddress,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        getSetting();
        if (res.data.error) {
          setstatusup(res.data.error)
          return "";
        }
        setstatusup("Updated succesfully")

      });
  };
  useEffect(() => {
    setroiper(settings !== null ? settings.staking_per : 0);
    settronAddress(settings !== null ? settings.tron_address : null);
  }, [settings]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
              <div className="me-auto d-none d-lg-block">
                <h3 className="text-black font-w600">Welcome to IFT!</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Members</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashd !== null ? dashd.t_users : 0}
                          </h2>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-success">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Stakings</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashd !== null ? dashd.t_staking : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashd !== null ? dashd.t_withdrawal : 0}
                          </h2>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-success">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Staking Income</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashd !== null ? dashd.staking_rew : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Level Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashd !== null ? dashd.level_rew : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total R.O.R</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashd !== null ? dashd.ror_rew : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-success">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today Staking Income</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashdtoday !== null ? dashdtoday.sincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today Level Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashdtoday !== null ? dashdtoday.levelincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today R.O.R</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashdtoday !== null ? dashdtoday.rorincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-secondary">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashdtoday !== null ? dashdtoday.withdrawal : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-secondary">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Pending Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashdtoday !== null ? dashdtoday.p_amount : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-secondary">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today ROI %</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                             {settings !== null ? settings.staking_per : 0} %
                          </h2>
                          <a
                            href="javascript:void(0);"
                            class="btn btn-primary mb-1"
                            data-bs-toggle="modal"
                            data-bs-target="#sendMessageModal"
                          >
                            📝
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Pending Principal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashdtoday !== null ? dashdtoday.t_p_principal : 0}
                          </h2>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-xxl-6 col-sm-6">
                <div className="card gradient-bx text-white bg-secondary">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Tron Address</p>
                        <div className="d-flex flex-wrap">
                          <h4 className="fs-40 font-w600 text-white mb-0 me-3">
                             {settings !== null ? settings.tron_address : null} 
                          </h4>
                          <a
                            href="javascript:void(0);"
                            class="btn btn-primary mb-1"
                            data-bs-toggle="modal"
                            data-bs-target="#tronAddressupdate"
                          >
                            📝
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade "
          id="sendMessageModal"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change ROI percentage</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <form className="comment-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="text-black font-w600 form-label">
                          Enter % 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={roiper}
                          onChange={(e)=>setroiper(e.target.value)}
                        />
                        <label>{statusup}</label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3 mb-0">
                        <button
                          type="button"
                          className="submit btn btn-primary"
                          onClick={() => upPer()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade "
          id="tronAddressupdate"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Deposit Address</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <form className="comment-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="text-black font-w600 form-label">
                          Enter Address 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={tronAddress}
                          onChange={(e)=>settronAddress(e.target.value)}
                        />
                        <label>{statusup}</label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3 mb-0">
                        <button
                          type="button"
                          className="submit btn btn-primary"
                          onClick={() => upAddress()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>             
            </div>
          </div>
        </div>
        
        <Footer />
      </div>
    </>
  );
}
