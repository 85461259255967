import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import { QRCodeCanvas } from "qrcode.react"; // Import QR code library

export default function KYCrecords() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState(null); // For QR code
  const [selectedTotalReceive, setSelectedTotalReceive] = useState(null); // Total Receive Amount
  const [scannedData, setScannedData] = useState(null); // Scanned QR code data
  const [showScanner, setShowScanner] = useState(false); // Toggle scanner
  const [hash, setHash] = useState("");
  const [w_id, setW_id] = useState("");
  const [kyc_data, setkyc_data] = useState(null);


  const getData = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "kyc",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        if (res.data.error) {
          setdata([]);
          setPages(0);
          setLoading(false);
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [pages, pageSize, currentPage]);

  const handleShowQR = (ele) => {
    setkyc_data(ele)
    setShowScanner(false); // Ensure scanner is hidden
  };


  const verifyKyc = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "kyc",
        submethod: "verify",
        key: process.env.REACT_APP_KEY,
        id: kyc_data._id,
      });

      if (response.data.data) {
        alert("KYC verified!");
        setkyc_data(null);
        getData();
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      alert("An error occurred during the KYC verification. Please try again.");
    }
  };
  const deleteKyc = async () => {


    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "kyc",
        submethod: "delete",
        key: process.env.REACT_APP_KEY,
        id: kyc_data._id,
      });

      if (response.data.data) {
        alert("Data deleted!");
        setkyc_data(null);
        getData();
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      alert("An error occurred during the KYC decline. Please try again.");
    }
  };


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  KYC
                </li>
              </ol>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">
                        KYC
                      </div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Aadhar Number</th>
                            <th>Datetime</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={8}>Data loading...</td>
                            </tr>
                          ) : null}
                          {data.map((ele, i) => {
                            return (
                              <tr key={ele.id}>
                                <td>{ele.id}</td>
                                <td>{ele.user_id}</td>
                                <td>{ele.aadhar_name}</td>
                                <td>{ele.aadhar_no}&nbsp;
                                  <button
                                    className="btn btn-primary mb-1 btn-xs"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tronAddressupdate"
                                    onClick={() =>
                                      handleShowQR(ele)
                                    }
                                  >
                                    ✔
                                  </button>
                                </td>
                                <td>{ele.datetime}</td>
                                <td>{ele.status ? "Verified" : "Not Verified"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="tronAddressupdate"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">KYC</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body text-center">
                <div>
                  <img src={`/kyc/${kyc_data ? kyc_data.aadhar_front_image : ''}`} alt="" srcset="" style={{ width: "300px", height: "auto" }} />
                </div>
                <br />
                <div>
                  <img src={`/kyc/${kyc_data ? kyc_data.aadhar_back_image : ''}`} alt="" srcset="" style={{ width: "300px", height: "auto" }} />
                </div>
                <p className="mt-3">
                  <strong>Aadhar Number: {kyc_data ? kyc_data.aadhar_no : ''}</strong>
                </p>
                <p className="mt-3">
                  <strong>Aadhar Name:  {kyc_data ? kyc_data.aadhar_name : ''}</strong>
                </p>
                <p className="mt-3">
                  <strong>Email:  {kyc_data ? kyc_data.email : ''}</strong>
                </p>
                <p className="mt-3">
                  <strong>User ID:  {kyc_data ? kyc_data.user_id : ''}</strong>
                </p>
                <div>

                </div>
                {kyc_data ? !kyc_data.status ?
                  <>
                    <button
                      className="btn btn-primary mt-3"
                      data-bs-dismiss="modal"
                      onClick={verifyKyc} // Call the function
                    >
                      Verify
                    </button>
                    <button
                      className="btn btn-danger mt-3"
                      data-bs-dismiss="modal"
                      onClick={deleteKyc} // Call the function
                    >
                      Decline
                    </button>
                  </> : '' : ''}
              </div>
            </div>
          </div>
        </div>


        <Footer />
      </div>
    </>
  );
}
