import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import axios from "axios";
import copy from "copy-to-clipboard";
import Web3 from "web3";

export const IFTContext = createContext();
const web3 = new Web3(process.env.REACT_APP_RPC);

function IFTcontext() {
  // var account = "0x4dF0485CB827A9b15f4c2aad20135039412557Cf"
  const [USER, setUSER] = useState([]);
  const [balance, setBalance] = useState(0);
  const [totalMembers, settotalMembers] = useState(0);
  const [settings, setsettings] = useState(null);
  const [dashd, setdashd] = useState([]);
  const [dashdtoday, setdashdtoday] = useState([]);
  const [dashdgold, setdashdgold] = useState([]);
  const [dashdcontract, setdashdcontract] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [tokenRate, settokenRate] = useState(0);
  const [gold, setgold] = useState(0);
  const [contract, setcontract] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("mark#321") || "")
  const [pwtoken, setPWToken] = useState(localStorage.getItem("2faAuth") || "")
  const [pWithdrawal, setPWithdrawal] = useState(false);
  const [fromLocation, setfromLocation] = useState("");

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  const copyText = (data) => {
    copy(data);
  };

  const getSetting = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("res", res.data.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };
  const getDashData = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("resdasdas", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashd(res.data.data);
        setdashdtoday(res.data.today);
      });
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyaddress",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };
  const getuserDB = async (account) => {
    var data = await getudata(account);
    if (data.data.error) {
      return "";
    }
    setuserdata(data.data.data[0]);
  };

  useEffect(() => {
    getSetting();
    getDashData();
  }, []);

  const getsmcs = async (account) => {
    if (typeof web3 === "undefined" || !settings.token) {
      return;
    }
    let contract = new web3.eth.Contract(
      JSON.parse(settings.token_abi),
      settings.token
    );
    let maincontract = new web3.eth.Contract(
      JSON.parse(settings.contract_abi),
      settings.contract
    );
    var df = await maincontract.methods.stakers().call();
    var getrate = await maincontract.methods.gettokenrate().call();
    var totalstakingd = await maincontract.methods.totalstakingd().call();
    var totalstakingt = await maincontract.methods.totalstakingt().call();
    var totalwithdrawald = await maincontract.methods.totalwithdrawald().call();
    var totalwithdrawalt = await maincontract.methods.totalwithdrawalt().call();
    settokenRate(web3.utils.fromWei(getrate, "ether"));
    var datap = {
      totalstakingd: web3.utils.fromWei(totalstakingd, "ether"),
      totalstakingt: web3.utils.fromWei(totalstakingt, "ether"),
      totalwithdrawald: web3.utils.fromWei(totalwithdrawald, "ether"),
      totalwithdrawalt: web3.utils.fromWei(totalwithdrawalt, "ether")
    };
    setdashdcontract(datap)
    settotalMembers(Number(df));
    setcontract(maincontract);
  };
  function formatDateTime(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  // useEffect(() => {
  // getsmcs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [settings, web3]);

  return (
    <>
      <IFTContext.Provider
        value={{
          settings: settings,
          web3,
          balance,
          userdata,
          getuserDB,
          copyText,
          getSetting,
          getsmcs,
          gold,
          USER,
          tokenRate,
          formatDateTime,
          formatAddress,
          contract,
          totalMembers,
          dashd,
          dashdtoday,
          dashdcontract,
          dashdgold,
          token, setToken, pwtoken, setPWToken,fromLocation, setfromLocation,pWithdrawal, setPWithdrawal
        }}
      >
        <App />
      </IFTContext.Provider>
    </>
  );
}

export default IFTcontext;
