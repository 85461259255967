import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [epoint,setepoint]=useState('')
  useEffect(() => {
    // Get the current URL endpoint (pathname)
    const currentEndpoint = window.location.pathname;
    setepoint(currentEndpoint)
  }, []);

  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={`${epoint==='/dashboard' ? "mm-active active-no-child":''}`}>
              <Link className={`ai-icon `} to="/dashboard" aria-expanded="false">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${epoint==='/members' || epoint.includes("/userreport") ? "mm-active active-no-child":''}`}>
              <Link to="/members" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-menu" />
                <span className="nav-text">Members</span>
              </Link>
            </li>
            <li className={`${epoint==='/staking-pool' ? "mm-active active-no-child":''}`}>
              <Link to="/staking-pool" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Staking Pool</span>
              </Link>
            </li>
            <li className={`${epoint==='/stakings' ? "mm-active active-no-child":''}`}>
              <Link to="/stakings" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Stakings</span>
              </Link>
            </li>
            <li className={`${epoint==='/staking-income' ? "mm-active active-no-child":''}`}>
              <Link to="/staking-income" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">Staking Income</span>
              </Link>
            </li>
            {/* <li className={`${epoint==='/ror-income' ? "mm-active active-no-child":''}`}>
              <Link to="/ror-income" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">R.O.R Income</span>
              </Link>
            </li> */}
            <li className={`${epoint==='/level-income' ? "mm-active active-no-child":''}`}>
              <Link to="/level-income" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">Level Income</span>
              </Link>
            </li>
            <li className={`${epoint==='/withdrawal' ? "mm-active active-no-child":''}`}>
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li>
            <li className={`${epoint==='/pen-withdrawal' ? "mm-active active-no-child":''}`}>
              <Link to="/pen-withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">P. Withdrawal</span>
              </Link>
            </li>
            <li className={`${epoint==='/kyc' ? "mm-active active-no-child":''}`}>
              <Link to="/kyc" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">KYC</span>
              </Link>
            </li>
            <li className={`${epoint==='/cron' ? "mm-active active-no-child":''}`}>
              <Link to="/cron" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book" />
                <span className="nav-text">Cron</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="javascript:void(0);"
                className="ai-icon has-arrow"
                aria-expanded="false"
              >
                <i className="flaticon-381-id-card-4" />
                <span className="nav-text">Report</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/referal-table">Referal</Link>
                </li>
                <li>
                  <Link to="/staking-income">Staking Income</Link>
                </li>
                <li>
                  <Link to="/withdrawal">Withdrawal Report</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link
                className="has-arrow ai-icon"
                to="javascript:void()"
                aria-expanded="false"
              >
                <i className="flaticon-381-television" />
                <span className="nav-text">Unstake</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/coin">Coin</Link>
                </li>
                <li>
                  <Link to="/gold">Gold</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
